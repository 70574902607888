export function filterCategories(searchResults, periodState, originState) {
  const hasPeriods = Object.values(periodState).some((val) => val);
  const hasOrigins = Object.values(originState).some((val) => val);

  if (hasPeriods && hasOrigins) {
    return searchResults.filter(
      (word) =>
        word.period.some((period) => periodState[period]) &&
        word.origin.some((origin) => originState[origin])
    );
  }

  if (hasPeriods) {
    return searchResults.filter((word) =>
      word.period.some((period) => periodState[period])
    );
  }

  if (hasOrigins) {
    return searchResults.filter((word) =>
      word.origin.some((origin) => originState[origin])
    );
  }

  return [];
}
