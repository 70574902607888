export const createInitialState = (filters, initialValue) =>
  filters.reduce((acc, current) => {
    acc[current.value] = initialValue;
    return acc;
  }, {});

export const createState = (filters, values) =>
  filters.reduce((acc, current) => {
    acc[current.value] = values.includes(current.value);
    return acc;
  }, {});
