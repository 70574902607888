import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { transformKey } from "../../utils/alphabetTransform";
import { filterCategories } from "../../utils/filterCategories";
import { getWordsMap } from "../../utils/wordsMap";
import SearchContext from "./SearchContext";
import {
  Search,
  SearchContainer,
  SearchLink,
  SideBar,
  Word,
  List,
} from "./SearchList.styled";

export default function SearchList({ words }) {
  const wordsMap = getWordsMap(words);
  const scrollList = useRef(null);

  const {
    // searchValue,
    // setSearchValue,
    // setSearchValueParam,
    activeWord,
    setActiveWord,
    setActiveWordParam,
    periodState,
    originState,
  } = useContext(SearchContext);

  const [searchResults, setSearchResults] = useState([]);

  // const handleChange = (event) => {
  //   const searchValue = transformKey(event.nativeEvent);
  //   setSearchValue(searchValue);
  //   setSearchValueParam(searchValue);
  // };

  const handleClick = (word) => {
    setActiveWord(word);
    setActiveWordParam(word);
  };

  useEffect(() => {
    setSearchResults(words);
  }, [periodState, originState]);

  useEffect(() => {
    setTimeout(() => {
      // scrollList.current.scroll(0, scroll);
    }, 0);
  }, []);

  return (
    <SideBar>
      <List ref={scrollList}>
        {filterCategories(searchResults, periodState, originState).map(
          (word) => (
            <Word key={word.id}>
              <SearchLink
                href="#"
                role="button"
                onClick={() => handleClick(word.name)}
                className={word.name === activeWord ? "active" : ""}
              >
                {word.name}
              </SearchLink>
            </Word>
          )
        )}
      </List>
    </SideBar>
  );
}
