import React, { useContext, useEffect, useMemo, useReducer } from "react";
import styled from "styled-components";
import { createInitialState } from "../../utils/createState";
import { Checkbox } from "../common/Checkbox";
import SearchContext from "./SearchContext";
const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 2px solid var(--grey);
`;
const Fieldset = styled.fieldset`
  padding-top: 0.5rem;
`;
const Button = styled.button`
  width: 120px;
  margin: auto;

  @media (max-width: 1100px) {
    width: 80px;
  }
`;
const Border = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--grey);
`;
const Legend = styled.legend`
  font-weight: bold;
  display: contents;
`;
export const SearchBar = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin: 2rem 3rem 2rem 0;
  flex-grow: 1;
  min-width: 200px;
  max-width: 200px;
  border-right: 2px solid var(--grey);
  /* border-bottom: 2px solid var(--grey); */

  @media (max-width: 1100px) {
    min-width: 110px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
`;


export default function SearchFilters({ origins, periods, hasStarted }) {
  const reducer = (state, action) => ({ ...state, ...action });

  const {
    periodState,
    setPeriodState,
    setPeriodsParam,
    originState,
    setOriginState,
    setOriginsParam,
  } = useContext(SearchContext);

  const [periodLocalState, setPeriodLocalState] = useReducer(
    reducer,
    createInitialState(periods, false)
  );
  const [originLocalState, setOriginLocalState] = useReducer(
    reducer,
    createInitialState(origins, false)
  );

  const selectedAllPeriod = useMemo(
    () => !Object.values(periodLocalState).includes(false),
    [periodLocalState]
  );

  const selectedAllOrigin = useMemo(
    () => !Object.values(originLocalState).includes(false),
    [originLocalState]
  );

  useEffect(() => {
    setPeriodLocalState(periodState);
    setOriginLocalState(originState);
  }, [hasStarted]);

  const submit = () => {
    setPeriodState(periodLocalState);

    setPeriodsParam(
      Object.entries(periodLocalState)
        .filter(([key, val]) => val)
        .map(([key, val]) => key)
        .join(",")
    );

    setOriginState(originLocalState);

    setOriginsParam(
      Object.entries(originLocalState)
        .filter(([key, val]) => val)
        .map(([key, val]) => key)
        .join(",")
    );
  };

  const selectAll = (isSelected, state, setState) => {
    const newState = createInitialState(state, !isSelected);
    setState(newState);
  };

  return (
    <Container>
      <SearchBar>
        <Button onClick={() => submit()}>SEARCH</Button>
      </SearchBar>
      <TagsContainer>
        <Fieldset>
          <Legend>Period (first attestation):</Legend>
          <Checkbox
            key="select-all-period"
            label="Select all"
            fnClick={() =>
              selectAll(selectedAllPeriod, periods, setPeriodLocalState)
            }
            checked={selectedAllPeriod}
          />
          {periods.map(({ value, label }) => (
            <Checkbox
              key={value}
              label={label}
              fnClick={(v) => {
                setPeriodLocalState({ [value]: v });
              }}
              checked={periodLocalState[value]}
            />
          ))}
        </Fieldset>
        <Fieldset>
          <Legend>Origin:</Legend>
          <Checkbox
            key="select-all-origin"
            label="Select all"
            fnClick={() =>
              selectAll(selectedAllOrigin, origins, setOriginLocalState)
            }
            checked={selectedAllOrigin}
          />
          {origins.map(({ value, label }) => (
            <Checkbox
              key={value}
              label={label === "Oriental" ? "Oriental (unknown source)" : label}
              fnClick={(v) => {
                setOriginLocalState({ [value]: v });
              }}
              checked={originLocalState[value]}
            />
          ))}
        </Fieldset>
        {/* {JSON.stringify(state)} */}
      </TagsContainer>
    </Container>
  );
}
