import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import SearchContext from "../components/search/SearchContext";
import SearchList from "../components/search/SearchList";
import { WordsListContainer } from "../components/common/WordListContainer";
import { parseCategories } from "../utils/parseCategories";
import SearchFilters from "../components/search/SearchFilters";
import { FlexColumn } from "../components/common/Styled";
import { createInitialState, createState } from "../utils/createState";
import Definition from "../components/common/Definition";
import { filterCategories } from "../utils/filterCategories";
const { parseDefinition } = require("../utils/parseDefinition");

export default function SearchPage({ data, pageContext }) {
  const {
    activeWord,
    setActiveWord,
    activeWordParam,
    periodState,
    setPeriodState,
    periodsParam,
    originState,
    setOriginState,
    originsParam,
  } = useContext(SearchContext);
  const wordNames = data.words.allEntries.map(({ name }) => name);

  const [hasStarted, setHasStarted] = useState(false);
  const [wordData, setWordData] = useState("");
  const origins = parseCategories(data.words.entryOrigins);
  const periods = parseCategories(data.words.entryPeriods);

  useEffect(() => {
    if (activeWord) {
      const word = filterCategories(
        data.words.allEntries,
        periodState,
        originState
      ).find(({ name }) => name === activeWord);
      setWordData(parseDefinition(word, wordNames));
    } else {
      setWordData("");
    }
  }, [activeWord, data.words.allEntries, wordData]);

  useEffect(() => {
    setTimeout(() => {
      if (originsParam?.length) {
        setOriginState(createState(origins, originsParam.split(",")));
      } else {
        setOriginState(createInitialState(origins, false));
      }

      if (periodsParam?.length) {
        setPeriodState(createState(periods, periodsParam.split(",")));
      } else {
        setPeriodState(createInitialState(periods, false));
      }

      if (activeWordParam) {
        setActiveWord(activeWordParam);
      } else {
        setActiveWord("");
      }
      setHasStarted(true);
    }, 0);
  }, []);

  return (
    <FlexColumn>
      <SEO
        title={
          pageContext.word ? `Definition of word ${wordData.name}` : `All Words`
        }
      />
      <SearchFilters
        origins={origins}
        periods={periods}
        hasStarted={hasStarted}
      />
      <WordsListContainer>
        <SearchList words={data.words.allEntries} wordsMap={data.wordsMap} />
        <Definition activeWord={wordData?.definition} idleContent="" />
      </WordsListContainer>
    </FlexColumn>
  );
}

export const query = graphql`
  query {
    words: LOOWIAG {
      allEntries {
        definition
        id
        name
        origin
        period
        pubDate
        status
      }
      entryOrigins
      entryPeriods
    }
  }
`;
