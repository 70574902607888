import React from "react";
import styled from "styled-components";

const Input = styled.input`
  margin-left: 1rem;
  margin-right: 0.5rem;
`;
const Container = styled.label`
  white-space: nowrap;
  margin-top: 0.5rem;
  display: inline-block;
`;

export const Checkbox = ({
  fnClick,
  fnChange,
  label = "",
  checked = false,
}) => (
  <Container>
    <Input
      onClick={(e) => {
        if (fnClick !== undefined) fnClick(e.target.checked);
      }}
      onChange={(e) => {
        if (fnChange !== undefined) fnChange(e.target.checked);
      }}
      type="checkbox"
      checked={checked}
    />
    {label}
  </Container>
);
