export function parseCategories(categories) {
  return categories.map((category) => {
    const [value, label] = category.split("', '");

    return {
      value: value.substring(2),
      label: label.substring(0, label.length - 2),
    };
  });
}
