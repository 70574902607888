const { parse } = require("node-html-parser");

function parseDefinition(word, wordNames) {
  if (!word) return "";
  const html = parse(word.definition);

  html.querySelectorAll("*[style]").forEach((el) => {
    if (el.attrs.style === "color:#0070c0") {
      const entryName = el.textContent;

      if (wordNames.includes(entryName)) {
        const newEl = parse(
          `<a class="entry-link" href="/lexicon/${entryName}">${el.toString()}</a>`
        );

        el.replaceWith(newEl);
      }
    }
  });

  html.querySelectorAll("p").forEach((el) => {
    if (el.textContent.startsWith("🕮")) {
      el.classList.add("biblio");
    }
  });

  word.definition = html.toString();

  return word;
}

module.exports = {
  parseDefinition,
};
